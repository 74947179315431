.ant-btn-primary {
    background-color: #fff;
    color: #000;
    border: 1px solid rgb(218, 209, 209);
    outline: none;
   
  }
 
.ant-btn-danger {
    background-color: #3ab569;
    color: #FFF;
    border: 1px solid rgb(218, 209, 209);
    outline: none;
   
  }
  @media only screen and (max-width: 992px) {
    .ant-btn-primary {
       padding: 0px 5px;
       font-size: 14px;
    }
  }
  .ant-btn-primary:hover,
  .ant-btn-danger:hover,
  .ant-btn-primary:focus ,
  .ant-btn-primary:active  {
    background: #3ab569;
    color: #fff;
    border: 1px solid #3ab569;
  }

  
  .ant-page-header {
    background: #c5ffdc;
    color: #fff;
    /* border: 1px solid #10f768; */
  }
  .ant-page-header-heading-sub-title{
    color:black;
  }
  /* .ant-page-header-heading:hover{ background: #a7f0c7;} */
  
   
 .ant-card-head-title{
   color:white;

 }
 .ant-card-head {
  border-radius: 50%;
 }
 .ant-card{
   border-radius: .5em .5em .5em .5em;
 }
 

  
  .site-layout{
    background-color: #fff;
  }

  .ant-layout-content{
    -webkit-box-shadow: -2px 6px 17px 2px rgba(0,0,0,0.1); 
    box-shadow: -2px 6px 17px 2px rgba(0,0,0,0.1);
    margin: 0px 10px ;
    padding: 15px;
  }

  .ant-breadcrumb{
    padding: 10px;
  }
  /* .ant-menu-item-only-child:hover,
  .ant-menu-item-selected:hover,
  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-title:active,
  .ant-menu-submenu-title:focus
  {
    color: #ED1C24;
  }
  .ant-menu-submenu-selected{
    color: #ED1C24;
  }
  .ant-menu-item:active {
    background-color: #ED1C24;
}
  .ant-menu-submenu > .ant-menu-submenu-title::selection{
    background-color: rgb(212, 75, 75);
  } */
  
  .ant-menu>.ant-menu-item:hover,
  .ant-menu>.ant-menu-submenu:hover,
  .ant-menu>.ant-menu-item-active,
  .ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected{
     color: #3ab569;
     border-bottom: 2 px solid #3ab569;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FFF;
    /* color: #3ab569; */
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #3ab569;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #3ab569;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #3ab569;
}

.ant-menu-item a:hover {
  color: #3ab569;
}
.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inlibe)
.ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: #3ab569;
}
.ant-menu>.ant-menu-light>.ant-menu-inline-collapsed>.ant-menu-root>.ant-menu-vertical{
  /* background-color: #3ab569; */
}

